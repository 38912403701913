.p-datatable-table {
  width: 100%;
  overflow-x: scroll;
}
.container-table-call {
  max-height: 80vh;
}
.p-button[aria-label="Aplicar"] {
  background-color: #36B37E;
  border: none;
}
.p-button[aria-label="Aplicar"]:hover {
  background-color: #2d996c;
  border: none;
}
.p-button[aria-label="Limpar"] {
  border: 2px solid #36B37E;
  color: #36B37E;
}
.p-button[aria-label="Limpar"]:hover {
  border: 2px solid #2d996c !important;
  color: #2d996c !important;
}
.p-button[aria-label="Adicionar filtro"] {
  color: #36B37E;
}
.p-button[aria-label="Adicionar filtro"]:hover {
  color: #2d996c !important;
}
.p-column-filter-menu-button.p-link.p-column-filter-menu-button-active {
  color: #36B37E !important;
  background-color: #ccffea;
}
input.p-inputtext:hover {
  border: 1px solid #36B37E !important;
  box-shadow: none;
}
.p-column-filter-matchmode-dropdown:hover {
  border: 1px solid #36B37E !important;
  box-shadow: none;
}
input.p-inputtext:focus {
  border: 1px solid #36B37E !important;
  box-shadow: none;
}
.p-column-filter-matchmode-dropdown {
  border: 1px solid #CCC !important;
  box-shadow: none;
}
.p-dropdown:not(.p-disable).p-focus {
  box-shadow: 0 0 0 .2rem #9adbc0;
  border: 1px solid #36B37E !important;
}
.p-calendar.p-inputwrapper:focus {
  box-shadow: 0 0 0 .2rem #9adbc0;
  border: 1px solid #36B37E !important;
}
@media(max-width: 600px) {
  .container-table-call {
    max-height: 70vh;
    overflow-y: scroll;
  }
}
@media(min-height: 650px) {
  .container-table-call {
    overflow-y: hidden;
  }
}