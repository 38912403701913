.layout-dash {
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 20px;
}

.grid-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.grid-item .card-info {
  transition: 300ms ease-in-out;
  cursor: pointer;
}

.card-info:hover {
  transform: translateY(-5px);
}

.animate-direction {
  animation: revertDirection 2000ms infinite;
}

@media (max-width: 1448px) {
  .grid-item {
  grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 960px) {
  .grid-item {
    grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 650px) {
  .grid-item {
    grid-template-columns: 1fr;
    }
}

@keyframes revertDirection {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }

}