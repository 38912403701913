@tailwind base;
@tailwind components;
@tailwind utilities;

.back-link {
  @apply text-2xl mr-5 text-blue-400 hover:text-white hover:bg-blue-400 duration-300 w-8 h-8 text-center rounded-full;
}
.btn-cadastro {
  @apply bg-primario hover:bg-primario_hover border-0 text-xs rounded-lg !important;
}
.btn-atualizar {
  @apply bg-attention hover:bg-attention_hover text-xs text-yellow_black border-0 !important;
}
.btn-apagar {
  @apply bg-perigo hover:bg-perigo_hover border-0 text-xs !important;
}
.btn-adicionar {
  @apply bg-blue-500 hover:bg-blue-400 border-0 text-xs !important;
}
.btn-cadastro-group {
  @apply bg-primario hover:bg-primario_hover rounded-r-md text-xs !important;
}
.icon-visualizar {
  @apply cursor-pointer text-blue-500 hover:text-white hover:bg-blue-500 px-2 py-2 text-sm rounded-full !important;
}
.icon-configuracao {
  @apply cursor-pointer text-gray-500 hover:text-white hover:bg-gray-500 px-2 py-2 text-sm rounded-full !important;
}
.icon-cronograma {
  @apply cursor-pointer text-green-500 hover:text-white hover:bg-green-500 px-2 py-1 text-sm rounded-full !important;
}
.icon-editar {
  @apply cursor-pointer text-slate-600 hover:text-white hover:bg-slate-500 px-2 py-1 text-sm rounded-full !important;
}
.icon-apagar {
  @apply cursor-pointer text-red-500 hover:text-white hover:bg-red-400 px-2 py-2 text-sm rounded-full !important;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  background-color: #333333;
}
.p-dropdown-item.p-highlight {
  background-color: rgba(54, 179, 126, 0.7) !important;
  color: #fff !important;
}
.p-datatable-tbody td {
  font-size: 12px !important;
}

.p-datatable-thead th {
  font-size: 12px !important;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.1rem rgba(54, 179, 126, 0.5);
}
.p-button.p-autocomplete-dropdown.p-button-icon-only:hover {
  background-color: rgba(54, 179, 126, 1) !important;
}

.p-checkbox-box.p-highlight {
  background-color: rgba(54, 179, 126, 1) !important;
  border: none !important;
}

.p-checkbox-box.p-highlight.p-focus {
  border: none !important;
  box-shadow: none !important;
}

@media (min-width: 1410px) {
  .p-datatable-tbody td {
    font-size: 16px !important;
  }
  .p-datatable-thead th {
    font-size: 16px !important;
  }
  .btn-cadastro {
    font-size: 17px !important;
  }
  .btn-atualizar {
    font-size: 17px !important;
  }
  .btn-apagar {
    font-size: 17px !important;
  }
  .btn-adicionar {
    font-size: 17px !important;
  }
  .icon-visualizar {
    font-size: 18px !important;
  }
  .icon-configuracao {
    font-size: 18px !important;
  }
  .icon-cronograma {
    font-size: 18px !important;
  }
  .icon-editar {
    font-size: 18px !important;
  }
  .icon-apagar {
    font-size: 18px !important;
  }
}

.p-datatable-wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
.p-datatable-wrapper::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #fff;
}
.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-primario;
}
.sidebar-scroll::-webkit-scrollbar {
  width: 0px;
}
.dropdown-filter .p-dropdown-label.p-inputtext.p-placeholder {
  font-size: 0.8rem;
  font-weight: bold;
}
.dropdown-filter {
  border: none;
  border-radius: 0px;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: auto;
}
.p-dropdown:not(.p-disabled):hover {
  border: none !important;
  outline: none !important;
}
.call-table .p-datatable-thead > tr > th {
  padding: 0rem !important;
}
.p-fluid .p-calendar .p-inputtext {
  border: none;
}
.p-fluid .p-calendar .p-inputtext::placeholder {
  font-size: 13px;
  font-weight: bold;
}
.p-fluid .p-calendar .p-inputtext:hover {
  border-radius: 0;
}
.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  background-color: transparent !important;
}

.p-button {
  border: none;
}

.p-button:focus {
  box-shadow: none;
}

.p-autocomplete-panel.panelSchool {
  overflow-y: scroll;
}

body {
  font-family: Roboto system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
