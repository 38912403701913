.p-togglebutton.p-highlight.toggle-theme:hover {
  background-color: #fff;
  color: #333;
}
.p-togglebutton.p-highlight.toggle-theme:hover .p-button-icon {
  color: #333;
}

.p-togglebutton.p-highlight.toggle-theme {
  background-color: #333;
  color: #fff;
}
.p-togglebutton.p-highlight.toggle-theme .p-button-icon {
  color: #fff;
}

.p-togglebutton.toggle-theme {
  background-color: #fff;
  color: #222;
}
.p-togglebutton.toggle-theme .p-button-icon {
  color: #222;
}

.toggle-theme:not(.p-highlight):hover {
  background-color: #333 !important;
  color: #fff !important;
}

.toggle-theme:not(.p-highlight):hover .p-button-icon {
  color: #fff !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #555;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #34D399 !important;
}