.scroll-bar::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
.scroll-bar::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #fff;
}
.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-primario;
}
.scroll-bar-none::-webkit-scrollbar {
  width: 1px;
  background-color: #fff;
}
.scroll-bar-none::-webkit-scrollbar:horizontal {
  height: 1px;
  background-color: #fff;
}
.scroll-bar-none::-webkit-scrollbar-thumb {
  border-radius: 100px;
  @apply bg-primario;
}