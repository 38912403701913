
.animation-down {
  animation: downs 500ms infinite;
}

@keyframes downs {
  0% { transform: translateY(0%) }
  10% { transform: translateY(3%) }
  20% { transform: translateY(6%) }
  30% { transform: translateY(9%) }
  40% { transform: translateY(12%) }
  50% { transform: translateY(15%) }
  60% { transform: translateY(12%) }
  70% { transform: translateY(9%) }
  80% { transform: translateY(6%) }
  90% { transform: translateY(3%) }
  100% { transform: translateY(0%) }
}